
.list-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
   
  }
  
  .my-list {
    list-style-type: none;
    padding: 0;
  }
  
  .list-item {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    cursor: pointer;
    transition:  transform 0.2s ease,background-color 0.2s ease,color 0.2s ease;
  }
  
  .company-name {
    font-size: 18px;
    font-weight: bold;
/*     
    color: #333; */
  }

  .list-item:hover{
   transform: scale(1.05);
    background-color:#f80000;
    color: #ffffff;
  }
  
  .site-location {
    display: block;
    font-size: 14px;
    color: #666;
  }