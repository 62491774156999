.project-card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgb(24, 3, 3);
    padding: 20px;
    text-align: center;
    width: 300px;
    margin-left: 800px;
    margin-top: 30px;
    transition: transform 0.3s ease;
  }
  
  .project-card h2 {
    font-size: 1.5rem;
  }
  
  .project-card .project-count {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 10px;
  }


  @media only screen and (max-width: 600px) {
    .project-card {
        margin-left: 70px;
        width: 65%;
        padding-left: 1px;
        padding-right: 1px;
        padding-bottom: 10px; /* Make the card square */
    }
}


.project-card:hover {
  transform: scale(1.05); 
}